import { useCallback, useEffect } from 'react';
import { useInitialize, useVisitorCode } from '@kameleoon/react-sdk';
import { consoleLogger } from 'helpers/analytics/console-logger';
import { getSegmentAnonymousId } from 'helpers/analytics/segment-helper';
import { ConsoleLoggerCategories, ConsoleType } from 'helpers/types/analytics';
import { useAppSelector } from 'redux/hooks';

const KameleoonInit = () => {
  const { isInitialized, initialize } = useInitialize();
  const { getVisitorCode } = useVisitorCode();
  const user = useAppSelector((store) => store.accountReducer.user);

  const init = useCallback(async (): Promise<void> => {
    try {
      if (!isInitialized()) {
        await initialize();
        const id = user?.attributes?.sub ?? (await getSegmentAnonymousId());
        getVisitorCode(id);
      }
    } catch (error) {
      consoleLogger({
        category: ConsoleLoggerCategories.API_FAILURE,
        consoleType: ConsoleType.ERROR,
        decision: 'Kameleoon failed to initialize',
        details: {
          error: error instanceof Error ? error.message : String(error),
        },
      });
    }
  }, [initialize, getVisitorCode, isInitialized, user]);

  useEffect(() => {
    init();
  }, [init]);
  return <></>;
};

export default KameleoonInit;
