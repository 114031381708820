import Script from 'next/script';

const KameleoonScript = () => {
  return (
    <>
      <Script type="text/javascript" id="kameleoon-script" strategy="afterInteractive">
        {`var kameleoonLoadingTimeout = 1000;

        window.kameleoonQueue = window.kameleoonQueue || [];
        window.kameleoonStartLoadTime = new Date().getTime();
        if (! document.getElementById("kameleoonLoadingStyleSheet") && ! window.kameleoonDisplayPageTimeOut)
        {
            var kameleoonS = document.getElementsByTagName("script")[0];
            var kameleoonCc = "* { visibility: hidden !important; background-image: none !important; }";
            var kameleoonStn = document.createElement("style");
            kameleoonStn.type = "text/css";
            kameleoonStn.id = "kameleoonLoadingStyleSheet";
            if (kameleoonStn.styleSheet)
            {
                kameleoonStn.styleSheet.cssText = kameleoonCc;
            }
            else
            {
                kameleoonStn.appendChild(document.createTextNode(kameleoonCc));
            }
            kameleoonS.parentNode.insertBefore(kameleoonStn, kameleoonS);
            window.kameleoonDisplayPage = function(fromEngine)
            {
                if (!fromEngine)
                {
                    window.kameleoonTimeout = true;
                }
                if (kameleoonStn.parentNode)
                {
                    kameleoonStn.parentNode.removeChild(kameleoonStn);
                }
            };
            window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, kameleoonLoadingTimeout);
        }`}
      </Script>
      <Script
        id="kameleeon-js"
        type="text/javascript"
        src="//1q9tiqxpps.kameleoon.eu/kameleoon.js"
        async={true}
        strategy="afterInteractive"
      />
    </>
  );
};

export default KameleoonScript;
