import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { addToCartClicked } from 'helpers/analytics/cart-product-added';
import { SegementCartTriggerSource } from 'helpers/analytics/cart-track';
import { consoleLogger } from 'helpers/analytics/console-logger';
import { CartErrors, ICart } from 'helpers/types';
import { ConsoleLoggerCategories, ConsoleLoggerServices, ConsoleType } from 'helpers/types/analytics';
import Auth from 'helpers/utils/cognito';
import { RootState } from 'redux/store';

interface ICartState {
  cartTrigger: SegementCartTriggerSource;
  isLoading: boolean;
  error: CartErrors | null;
  carts?: { [key: string]: ICart };
  checkoutCompletedUUID: string;
}

interface CreateCartPayload {
  store_id: number;
  reservation_mode: string;
}

const initialState: ICartState = {
  cartTrigger: null,
  isLoading: false,
  error: null,
  carts: {},
  checkoutCompletedUUID: null,
};

const BASE_API_URL = process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots/carts_api/v1';
const getAuthToken = async () => {
  const session = await Auth.getSession();
  return session?.getAccessToken()?.getJwtToken();
};

/**
 * Creates a new cart for the user at a specified store with a given fulfillment type.
 *
 * This function uses the Jane API to create a new cart. If the user already has an active cart
 * (detected via a 409 error from the API), it will instead sync the cart and update the
 * user's attributes in the authentication service (Cognito).
 *
 * @function createCart
 *
 * @param {Object} payload - The payload for creating the cart.
 * @param {number} payload.store_id - The ID of the store where the cart will be created.
 * @param {string} payload.reservation_mode - The type of fulfillment (e.g., 'pickup' or 'delivery').
 *
 * @param {Object} thunkAPI - The second argument provided by createAsyncThunk, which includes:
 * @param {Function} thunkAPI.dispatch - Redux's dispatch function for triggering actions.
 * @param {Function} thunkAPI.getState - Function to access the current Redux state.
 * @param {Function} thunkAPI.rejectWithValue - Function to return a custom rejected action payload.
 *
 * @returns {Promise<Object>} - Returns the newly created cart data or an existing cart when a conflict occurs.
 *
 * @throws {Error} - Throws an error if the API call fails and the error is not a 409 conflict. If the cart already
 * exists, a custom error is thrown and the existing cart is synced.
 *
 * Workflow:
 * 1. Fetches the authorization token for the current user.
 * 2. Calls the Jane API to create a new cart with the provided store and reservation mode.
 *    - If the API call returns an error, it checks for a 409 status (conflict) indicating the cart already exists.
 *    - In the event of a 409 error, it dispatches `syncCart` to retrieve the existing cart and update the Redux store.
 * 3. On success, updates the user's custom cart attributes (`custom:current_cart_id`) in the authentication service (Cognito).
 * 4. If the cart creation or sync is successful, returns the cart data. Otherwise, returns a rejected value with the error message.
 *
 * Error Handling:
 * - If a 409 error is encountered (i.e., the user already has an active cart), the function dispatches `syncCart`
 *   to fetch and update the existing cart in the Redux state.
 * - For other errors, the function uses `rejectWithValue` to return a rejected action containing the error message.
 *
 */
export const createCart = createAsyncThunk(
  'cart/createCart',
  async (payload: CreateCartPayload, { dispatch, rejectWithValue }) => {
    const { store_id, reservation_mode } = payload;
    try {
      // Fetch authorization token for the current user
      const token = await getAuthToken();

      // Log the API call for debugging purposes
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Create Cart',
        details: {
          requestPayload: payload,
          service: ConsoleLoggerServices.JANE,
          method: 'POST',
          description: 'Calling Create cart on Jane API',
          endpoint: `${BASE_API_URL}/carts`,
        },
      });

      // Send request to Jane API to create a cart
      const response = await axios.post(`${BASE_API_URL}/carts`, payload, { headers: { Authorization: token } });

      // Handle API response errors
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors.error);
      }

      // Return the newly created cart data
      return response?.data?.data;
    } catch (error) {
      const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
      const existingCartId = error?.response?.data?.errors?.existing_cart_uuid;

      // Handle 401 unauthorized
      if (errorStatus === 401) {
        await consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Sign out user',
          details: {
            service: ConsoleLoggerServices.JANE,
            errorCode: errorStatus,
            description: 'Received unexpected 401 response for createCart.',
          },
        });
        Auth.signOut();
        return rejectWithValue('User session expired, please sign in.');
      }

      // Handle cart conflict (409 status) by syncing the existing cart
      if (errorStatus === 409 && existingCartId) {
        await consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Creating Cart Failed',
          details: {
            service: ConsoleLoggerServices.JANE,
            details: "API call failed with message 'User already has an active cart.' Now calling syncCart",
          },
        });
        // Dispatch syncCart to update the existing cart in Redux
        const response = await dispatch(
          syncCart({ cartUUID: existingCartId, store_id, fullfillmentType: reservation_mode }),
        );
        // Return the synced cart data
        return { ...response?.payload };
      } else {
        // Return the error message in case of failure
        return rejectWithValue(error?.response?.data?.errors?.error || error.message);
      }
    }
  },
);

/**
 * addOrUpdateToCart calls the createCart thunk to get a new cart or existing cart
 * It'll then check to see if the pvid exists in the returned cart
 * If yes, do an update with the current quantity + selected quantity
 * Else, do an add with the selected quantity
 */
const addOrUpdateToCart = async (
  {
    store_id,
    fullfillmentType,
    pvid,
    quantity,
  }: {
    store_id: number;
    fullfillmentType: string;
    pvid: string;
    quantity: number;
  },
  { dispatch, rejectWithValue },
) => {
  try {
    const response = await dispatch(createCart({ store_id, reservation_mode: fullfillmentType }));
    const newUUID = response?.payload?.cart_uuid;
    const items = response?.payload?.items;
    const token = await getAuthToken();
    //If Item exists in returned cart
    if (items[pvid] && items[pvid]?.quantity) {
      // Log the API call for debugging purposes
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Update Cart',
        details: {
          requestPayload: { quantity: quantity + items[pvid]?.quantity },
          service: ConsoleLoggerServices.JANE,
          method: 'PUT',
          description: 'Calling Update cart on Jane API',
          endpoint: `${BASE_API_URL}/carts/${newUUID}/items/${pvid}`,
        },
      });
      const response = await axios.put(
        `${BASE_API_URL}/carts/${newUUID}/items/${pvid}`,
        { quantity: quantity + items[pvid]?.quantity },
        { headers: { Authorization: token } },
      );
      if (response?.data?.errors) throw new Error(response?.data?.errors.error);
      return response?.data?.data;
      //Item doesn't exist, so add item to cart
    } else {
      // Log the API call for debugging purposes
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Add to Cart',
        details: {
          requestPayload: { pvid, quantity },
          service: ConsoleLoggerServices.JANE,
          method: 'POST',
          description: 'Calling Add to cart on Jane API',
          endpoint: `${BASE_API_URL}/carts/${newUUID}/items`,
        },
      });
      const response = await axios.post(
        `${BASE_API_URL}/carts/${newUUID}/items`,
        { pvid, quantity },
        { headers: { Authorization: token } },
      );
      if (response?.data?.errors) throw new Error(response?.data?.errors.error);
      return response?.data?.data;
    }
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors?.error || error.message);
  }
};

export const addItemToCart = createAsyncThunk(
  'cart/addItemToCart',
  async (
    {
      cartUUID,
      pvid,
      quantity,
      addCartEvent,
      store_id,
      fullfillmentType,
    }: {
      cartUUID: string;
      pvid: string;
      quantity: number;
      addCartEvent;
      store_id: number;
      fullfillmentType: string;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const token = await getAuthToken();
      // Log the API call for debugging purposes
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Add to Cart Cart',
        details: {
          requestPayload: { pvid, quantity },
          service: ConsoleLoggerServices.JANE,
          method: 'POST',
          description: 'Calling Add to cart cart on Jane API',
          endpoint: `${BASE_API_URL}/carts/${cartUUID}/items`,
        },
      });
      const response = await axios.post(
        `${BASE_API_URL}/carts/${cartUUID}/items`,
        { pvid, quantity },
        { headers: { Authorization: token } },
      );
      if (response?.data?.errors) throw new Error(response?.data?.errors.error);

      addToCartClicked({ eventItem: addCartEvent, cartUUID });

      return response?.data?.data;
    } catch (error) {
      const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
      // Handle 401 unauthorized
      if (errorStatus === 401) {
        await consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Sign out user',
          details: {
            service: ConsoleLoggerServices.JANE,
            errorCode: errorStatus,
            description: 'Received unexpected 401 respons for addItemToCart.',
          },
        });
        Auth.signOut();
        return rejectWithValue('User session expired, please sign in.');
      }

      // If we get a cart not found error, call our addOrUpdateToCart function to resync/make a new cart
      // and add/update the item in the cart
      if (error?.response?.status == 404 && error?.response?.data?.errors?.error === 'Cart not found') {
        consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Adding to cart Failed',
          details: {
            service: ConsoleLoggerServices.JANE,
            details:
              "API call failed with message 'Cart not found.' Now calling addOrUpdateToCart to first get a new cart or existing cart, then update or add the item depending on if the item is already in the cart or not",
          },
        });
        try {
          const response = await addOrUpdateToCart(
            { store_id, fullfillmentType, pvid, quantity },
            { dispatch, rejectWithValue },
          );
          return response?.data?.data;
        } catch (error) {
          return rejectWithValue(error?.response?.data?.errors?.error || error.message);
        }
      } else {
        return rejectWithValue(error?.response?.data?.errors?.error || error.message);
      }
    }
  },
);

export const updateCartItemQuantity = createAsyncThunk(
  'cart/updateCartItemQuantity',
  async (
    {
      cartUUID,
      pvid,
      quantity,
      store_id,
      fullfillmentType,
    }: { cartUUID: string; pvid: string; quantity: number; store_id: number; fullfillmentType: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const token = await getAuthToken();
      // Log the API call for debugging purposes
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Update Cart',
        details: {
          requestPayload: { quantity },
          service: ConsoleLoggerServices.JANE,
          method: 'PUT',
          description: 'Calling Update cart on Jane API',
          endpoint: `${BASE_API_URL}/carts/${cartUUID}/items/${pvid}`,
        },
      });
      const response = await axios.put(
        `${BASE_API_URL}/carts/${cartUUID}/items/${pvid}`,
        { quantity },
        { headers: { Authorization: token } },
      );

      if (response?.data?.errors) throw new Error(response?.data?.errors.error);

      return response?.data?.data;
    } catch (error) {
      const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
      // Handle 401 unauthorized
      if (errorStatus === 401) {
        await consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Sign out user',
          details: {
            service: ConsoleLoggerServices.JANE,
            errorCode: errorStatus,
            description: 'Received unexpected 401 respons for updateCartItemQuantity.',
          },
        });
        Auth.signOut();
        return rejectWithValue('User session expired, please sign in.');
      }

      // If we get a cart not found error, call our addOrUpdateToCart function to resync/make a new cart
      // and add/update the item in the cart
      if (
        error?.response?.status == 400 &&
        error?.response?.data?.errors?.validations?.cart_uuid?.[0] === "cart_uuid Cart with given UUID doesn't exist"
      ) {
        consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Updating to cart Failed',
          details: {
            service: ConsoleLoggerServices.JANE,
            details:
              "API call failed with message 'Cart not found.' Now calling addOrUpdateToCart to first get a new cart or existing cart, then update or add the item depending on if the item is already in the cart or not",
          },
        });
        try {
          const response = await addOrUpdateToCart(
            { store_id, fullfillmentType, pvid, quantity },
            { dispatch, rejectWithValue },
          );
          return response;
        } catch (error) {
          return rejectWithValue(error?.response?.data?.errors?.error || error.message);
        }
      } else {
        return rejectWithValue(error?.response?.data?.errors?.error || error.message);
      }
    }
  },
);

export const removeCartItem = createAsyncThunk(
  'cart/removeCartItem',
  async ({ cartUUID, pvid }: { cartUUID: string; pvid: string }, { rejectWithValue }) => {
    try {
      const token = await getAuthToken();
      // Log the API call for debugging purposes
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Remove from Cart',
        details: {
          service: ConsoleLoggerServices.JANE,
          method: 'DELETE',
          description: 'Calling Remove from cart on Jane API',
          endpoint: `${BASE_API_URL}/carts/${cartUUID}/items/${pvid}`,
        },
      });
      const response = await axios.delete(`${BASE_API_URL}/carts/${cartUUID}/items/${pvid}`, {
        headers: { Authorization: token },
      });

      if (response?.data?.errors) throw new Error(response?.data?.errors.error);

      return response.data.data;
    } catch (error) {
      const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
      // Handle 401 unauthorized
      if (errorStatus === 401) {
        await consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Sign out user',
          details: {
            service: ConsoleLoggerServices.JANE,
            errorCode: errorStatus,
            description: 'Received unexpected 401 respons for removeCartItem.',
          },
        });
        Auth.signOut();
        return rejectWithValue('User session expired, please sign in.');
      }

      return rejectWithValue(error?.response?.data?.errors?.error || error.message);
    }
  },
);

export const syncCart = createAsyncThunk(
  'cart/syncCart',
  async (
    { cartUUID, store_id, fullfillmentType }: { cartUUID: string; store_id: number; fullfillmentType: string },
    { rejectWithValue },
  ) => {
    const token = await getAuthToken();
    try {
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.DEBUG,
        decision: 'Calling Get Cart',
        details: {
          requestPayload: {
            cartUUID,
          },
          service: ConsoleLoggerServices.JANE,
        },
      });
      const response = await axios.get(`${BASE_API_URL}/carts/${cartUUID}`, {
        headers: { Authorization: token },
      });

      if (response?.data?.errors) throw new Error(response?.data?.errors.error);

      const cartData = response?.data?.data;
      cartData.synced = true; // Add synced flag for conditionals in checkout-cart.ts
      return cartData;
    } catch (error) {
      const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
      // Handle 401 unauthorized
      if (errorStatus === 401) {
        await consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Sign out user',
          details: {
            service: ConsoleLoggerServices.JANE,
            errorCode: errorStatus,
            description: 'Received unexpected 401 respons for syncCart.',
          },
        });
        Auth.signOut();
        return rejectWithValue('User session expired, please sign in.');
      }
      // If we get a cart not found error, try and make a new cart.
      // Return the new cart
      // If the cart API returns us an existing cart, fetch and return the cart
      if (error?.response?.status == 404 && error?.response?.data?.errors?.error === 'Cart not found') {
        consoleLogger({
          category: ConsoleLoggerCategories.API_FAILURE,
          consoleType: ConsoleType.ERROR,
          decision: 'Retrieving Cart Failed',
          details: {
            service: ConsoleLoggerServices.JANE,
            details:
              "API call failed with message 'Cart not Found' Now calling createCart async thunk to get a new cart or Jane's existing cart",
          },
        });
        try {
          const payload = { store_id, reservation_mode: fullfillmentType };
          // Log the API call for debugging purposes
          await consoleLogger({
            category: ConsoleLoggerCategories.API_CALL,
            consoleType: ConsoleType.DEBUG,
            decision: 'Calling Create Cart after Sync cart returned us Cart not found',
            details: {
              requestPayload: payload,
              service: ConsoleLoggerServices.JANE,
              method: 'POST',
              description: 'Calling Create cart on Jane API',
              endpoint: `${BASE_API_URL}/carts`,
            },
          });
          const response = await axios.post(`${BASE_API_URL}/carts`, payload, { headers: { Authorization: token } });
          return response?.data?.data;
        } catch (error) {
          const errorStatus = error?.response?.data?.errors?.status;
          const existingCartId = error?.response?.data?.errors?.existing_cart_uuid;
          if (errorStatus === 409 && existingCartId) {
            // Log the API call for debugging purposes
            await consoleLogger({
              category: ConsoleLoggerCategories.API_CALL,
              consoleType: ConsoleType.DEBUG,
              decision: 'Calling get Cart after Sync cart returned us an existing cart',
              details: {
                service: ConsoleLoggerServices.JANE,
                method: 'GET',
                description: 'Calling Create cart on Jane API',
                endpoint: `${BASE_API_URL}/carts/${existingCartId}`,
              },
            });
            const response = await axios.get(`${BASE_API_URL}/carts/${existingCartId}`, {
              headers: { Authorization: token },
            });
            return { ...response?.data?.data };
          } else {
            return rejectWithValue(error?.response?.data?.errors?.error || error.message);
          }
        }
      } else {
        return rejectWithValue(error?.response?.data?.errors?.error || error.message);
      }
    }
  },
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart: (state, action) => {
      state.isLoading = false;
      const { store_id } = action.payload || {};
      state.carts[store_id] = {
        items: {},
        subTotal: null,
        discountPrice: null,
        cart_uuid: state.carts[store_id].cart_uuid,
      };
    },
    clearAllCarts: (state) => {
      state.isLoading = false;
      state.carts = {};
    },
    updateCartQuantity: (state, action) => {
      const { store_id, items } = action.payload || {};
      const cart = state.carts[store_id] as ICart;
      if (cart) {
        cart.items = items;
      }
    },
    addToCarts: (state, action: PayloadAction<{ store_id: number | string; cart: ICart }>) => {
      const { store_id, cart } = action.payload || {};
      state.carts[store_id] = cart;
    },
    removeFromCarts: (state, action: PayloadAction<{ store_id: string }>) => {
      const { store_id } = action.payload || {};
      delete state.carts[store_id];
    },
    setCheckoutCompletedUUID: (state, action) => {
      state.checkoutCompletedUUID = action.payload;
    },
    setCartTrigger: (state, action) => {
      state.cartTrigger = action.payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCart.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { cart_uuid, store_id } = action.payload || {};
        state.carts[store_id] = { items: {}, cart_uuid, subTotal: null, discountPrice: null };
      })
      .addCase(createCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      })
      .addCase(addItemToCart.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { store_id, items } = action.payload || {};
        const cart = state.carts[store_id] as ICart;
        if (cart) {
          cart.items = items;
        }
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      })
      .addCase(updateCartItemQuantity.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { store_id, items } = action.payload || {};
        const cart = state.carts[store_id] as ICart;
        cart.items = items;
      })
      .addCase(updateCartItemQuantity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      })
      .addCase(removeCartItem.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { store_id, items } = action.payload || {};
        const cart = state.carts[store_id] as ICart;
        if (cart) {
          cart.items = items;
        }
      })
      .addCase(removeCartItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      }) // SYNC CART STUFF
      .addCase(syncCart.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(syncCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { cart_uuid, store_id, items } = action.payload || {};
        const cart = state.carts[store_id] as ICart;

        if (cart) {
          cart.cart_uuid = cart_uuid;
          cart.items = items;
        } else {
          // If cart doesn't exist, add it
          state.carts[store_id] = { items, cart_uuid, subTotal: null, discountPrice: null };
        }
      })
      .addCase(syncCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      });
  },
});

export const selectCart = (state: RootState) => state.cart;
export const { setCartTrigger, addToCarts, removeFromCarts, clearAllCarts } = cartSlice.actions;

export default cartSlice.reducer;
