import { useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { EndChatGate } from 'components/atoms/amazon-connect-chat/chat-end-chat-gate';
import { Typography } from 'components/atoms/typography';
import {
  ACFrameContainer,
  ACRootContainer,
  ACWidgetContainer,
  ACWrapper,
  ChatBubble,
  ChatWrapper,
  ConnectCustomerInterfaceContainer,
  ContentWrapper,
  ExitButtonContainer,
  HeaderContainer,
  LogoHeaderWrapper,
  LogoWrapper,
  StyledExit,
  StyledMinimize,
} from 'components/molecules/modals/chat-modal/styles';
import Loading from 'components/organisms/global/loader';
import { ACConfig } from 'helpers/amazon-chat-client/config';
import { useChatEventTracking } from 'helpers/hooks/use-chat-event-tracking';
import useDispensary from 'helpers/hooks/use-dispensary';
import { shouldChatLoad } from 'helpers/utils/should-chat-load';
import ChatIcon from 'public/icons/rise-icons/Chat.svg';
import { setAgentsAvailable, setChatMinimized, setIsChatOpen } from 'redux/reducers/chat-slice';
import { RootState } from 'redux/store';

const ChatModalOffline = dynamic(() => import('./chat-offline'));

type TChatModal = {
  renderContents?: boolean; //used to not render the chat widget or form within the modal if rendering in storybook
};

const ChatModal = ({ renderContents = false }: TChatModal) => {
  const ChatWidget = renderContents
    ? null
    : dynamic(() => import('components/organisms/amazon-connect-chat/index'), {
        loading: () => <Loading />,
        ssr: false,
      });
  const dispatch = useDispatch();
  const [endChat, setEndChat] = useState(false);
  const resetEndChat = () => setEndChat(false);
  const [endChatGate, setEndChatGate] = useState(false);
  const { isChatOpen, modalData, isChatContentEditable, agentsAvailableFromRedux } = useSelector(
    (state: RootState) => state.chat,
  );
  const { openChatEvent } = useChatEventTracking();
  const [isAgentsAvailable, setIsAgentsAvailable] = useState<boolean | null>(agentsAvailableFromRedux?.data);

  const { title, logo } = modalData;
  const chatWrapperRef = useRef(null);
  const hasAgentJoinedFromSession = JSON.parse(window.sessionStorage.getItem('hasAgentJoined')) || false;
  const minimizeChatFromSession = JSON.parse(window.sessionStorage.getItem('minimizeChat'));
  const [minimizeChat, setMinimizeChat] = useState(hasAgentJoinedFromSession && minimizeChatFromSession);

  const hideChat = () => {
    dispatch(setChatMinimized(true));
    setMinimizeChat(true);
  };

  const showChat = () => {
    openChatEvent();
    dispatch(setChatMinimized(false));
    setMinimizeChat(false);
  };

  useEffect(() => {
    const chatStateFromSession = JSON.parse(window.sessionStorage.getItem('isChatOpen'));
    const chatDetailsFromSession = JSON.parse(window.sessionStorage.getItem('chatDetails'));
    const minimizeChatFromSession = JSON.parse(window.sessionStorage.getItem('minimizeChat'));
    const hasAgentJoinedFromSession = JSON.parse(window.sessionStorage.getItem('hasAgentJoined')) || false;
    const navigationType = (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type;
    if (chatStateFromSession && navigationType !== 'navigate') dispatch(setIsChatOpen(chatStateFromSession));
    if (minimizeChatFromSession) {
      setMinimizeChat(hasAgentJoinedFromSession && minimizeChatFromSession);
      dispatch(setChatMinimized(minimizeChatFromSession));
    }

    if (
      (navigationType === 'reload' || navigationType === 'back_forward') &&
      chatStateFromSession &&
      chatDetailsFromSession
    ) {
      dispatch(setAgentsAvailable(true));
    }
  }, []);
  useEffect(() => {
    (async () => {
      if (isAgentsAvailable === null && isChatOpen) {
        const response = await shouldChatLoad();
        setIsAgentsAvailable(response);
        dispatch(setAgentsAvailable(response));
      }
    })();
  }, [isAgentsAvailable, isChatOpen]);

  useEffect(() => {
    setTimeout(() => {
      window.sessionStorage.setItem('isChatOpen', JSON.stringify(isChatOpen));
      window.sessionStorage.setItem('minimizeChat', JSON.stringify(minimizeChat));
    }, 1000);
  }, [isChatOpen, minimizeChat]);

  useEffect(() => {
    const isChatEnded = JSON.parse(window.sessionStorage.getItem('isChatEnded'));
    if (isChatOpen && isChatEnded) {
      window.sessionStorage.removeItem('isChatEnded');
    }
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (chatWrapperRef.current && !chatWrapperRef.current.contains(event.target) && !endChatGate) {
        const hasAgentJoinedFromSession = JSON.parse(window.sessionStorage.getItem('hasAgentJoined'));
        if (hasAgentJoinedFromSession) {
          setMinimizeChat(true);
          dispatch(setChatMinimized(true));
        } else {
          dispatch(setIsChatOpen(false));
        }
      }
    },
    [dispatch, setIsChatOpen, endChatGate, chatWrapperRef.current, chatWrapperRef.current],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const { currentDispensary } = useDispensary();
  const shouldShowChat = currentDispensary?.dispensaries?.showChatBox;

  if (!isChatOpen && !hasAgentJoinedFromSession) return null;

  const closeChatModal = () => {
    dispatch(setIsChatOpen(false));
    window.sessionStorage.setItem('isChatOpen', JSON.stringify(false));
  };

  return !shouldShowChat ? (
    <></>
  ) : !minimizeChat ? (
    <ACWrapper data-testid="acWrapper">
      <ACWidgetContainer $showChat={!minimizeChat}>
        {endChatGate && <EndChatGate setEndChat={setEndChat} setEndChatGate={setEndChatGate} />}
        {agentsAvailableFromRedux.loading && <Loading />}
        <ACFrameContainer>
          <ACRootContainer>
            <ConnectCustomerInterfaceContainer>
              <ChatWrapper ref={chatWrapperRef}>
                <HeaderContainer>
                  <LogoHeaderWrapper>
                    {logo && (
                      <LogoWrapper>
                        <Image
                          height={logo.height}
                          width={logo.width}
                          src={logo.url}
                          alt={logo.title}
                          loader={(options) => options?.src}
                          data-testid="logoImage"
                        />
                      </LogoWrapper>
                    )}
                  </LogoHeaderWrapper>
                  <Typography variant="body-small-bold" className="my-auto !text-white" data-testid="titleHeading">
                    {title}
                  </Typography>
                  <ExitButtonContainer>
                    <button
                      data-close
                      aria-label="Close Chat Window"
                      type="button"
                      onClick={isAgentsAvailable && isChatContentEditable ? hideChat : closeChatModal}
                      data-testid="exitMinimizeButton"
                    >
                      {isAgentsAvailable && isChatContentEditable ? (
                        <StyledMinimize aria-hidden="true" />
                      ) : (
                        <StyledExit aria-hidden="true" data-testid="exitStyled" />
                      )}
                    </button>
                  </ExitButtonContainer>
                </HeaderContainer>
                {!agentsAvailableFromRedux.loading && (
                  <ContentWrapper className={isAgentsAvailable ? '' : 'md:!max-h-[90%]'}>
                    {renderContents ? null : isAgentsAvailable ? (
                      <ChatWidget
                        setEndChatGate={setEndChatGate}
                        endChat={endChat}
                        resetEndChat={resetEndChat}
                        props={ACConfig}
                      />
                    ) : (
                      <ChatModalOffline modalData={modalData} onClose={closeChatModal} />
                    )}
                  </ContentWrapper>
                )}
              </ChatWrapper>
            </ConnectCustomerInterfaceContainer>
          </ACRootContainer>
        </ACFrameContainer>
      </ACWidgetContainer>
    </ACWrapper>
  ) : (
    <ChatBubble onClick={showChat} data-testid="chatBubble">
      <ChatIcon className="text-resin" />
    </ChatBubble>
  );
};

export default ChatModal;
